.footer {
    position: fixed;
    height: 50px;
    background-color: #343a40;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin-bottom: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.copyright {
    color: #ffffff;
    text-align: center;
    margin-top: 15px;
}

.ig {
    color: #ffffff;
}
